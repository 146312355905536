import { Component, OnInit } from '@angular/core';

import { combineLatest, filter, interval, map } from 'rxjs';
import { StatusBar, Style } from '@capacitor/status-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ActionSheetController,
  MenuController,
  Platform,
} from '@ionic/angular';
import * as AddToHomeScreen from 'a2hs.js';
import { SwUpdate } from '@angular/service-worker';

import {
  ASSET_LOCATIONS,
  ROUTE_URLS,
  STORAGE_KEYS,
} from '@ers-cat-app/shared/constants';
import { AuthService } from './shared/services/auth/auth.service';
import { NetworkService } from './shared/services/network-service/network.service';
import { EquipmentService } from './shared/services/equipment/equipment.service';
import { a2hsOptions } from '@ers-cat-app/shared/constants';
import { UserWithRoles } from '@ers/shared';
import { SystemService, UserService } from './shared/services';
import { MenuItem, MenuService } from './shared/services/menu/menu.service';
import { NavigationEnd, Params, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'ers-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly ROUTE_URLS = ROUTE_URLS;
  readonly ASSET_LOCATIONS = ASSET_LOCATIONS;

  get primaryLinks() {
    return this.menuService.menu;
  }

  secondaryLinks = [
    { title: 'Brochures and Flyers', url: ROUTE_URLS.BROCHURES_AND_FLYERS },
  ];

  secondaryLinksDisabled = [
    { title: 'Credit Application', url: '/' },
    { title: 'Customer Portal', url: '/' },
    { title: 'Customer Set Up Docs', url: '/' },
    { title: 'Locate Nearest Branch', url: '/' },
  ];

  isMobileWeb = false;
  isLoggedIn = false;
  user: UserWithRoles | undefined;
  profilePhoto: Blob | undefined;
  objectUrl?: string;

  constructor(
    readonly networkService: NetworkService,
    readonly authService: AuthService,
    readonly equipmentService: EquipmentService,
    readonly platform: Platform,
    readonly systemService: SystemService,
    readonly router: Router,
    private readonly swUpdate: SwUpdate,
    private readonly menu: MenuController,
    private readonly userService: UserService,
    private readonly menuService: MenuService,
    private readonly actionSheetCtrl: ActionSheetController,
  ) {
    this.platform = platform;
    const platforms = this.platform.platforms();

    this.isMobileWeb =
      platforms.includes('mobileweb') &&
      platforms.includes('ios') &&
      !platforms.includes('pwa');
  }

  get hideMenu() {
    return (
      this.authService.isDpsWorkOrderUser ||
      this.router.url.toLowerCase().includes(ROUTE_URLS.BUSINESS_CARD)
    );
  }

  async ngOnInit() {
    // If user is logged in, get user info and setup side menu
    this.authService.isLoggedIn$
      .pipe(untilDestroyed(this))
      .subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;

        if (isLoggedIn) {
          localStorage.removeItem(STORAGE_KEYS.IS_LOGGING_IN);
          this.getUserInfo();
        }
      });

    await this.addToHomeScreen();

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(event => {
        switch (event.type) {
          case 'VERSION_READY':
            if (confirm('New version available. Load New Version?')) {
              window.location.reload();
            }
            break;
          case 'VERSION_DETECTED':
            // console.log('update Detected');

            break;
          case 'VERSION_INSTALLATION_FAILED':
            // console.log('update Failed');
            break;

          default:
            // console.log('no update found');
            break;
        }
      });
    }

    // Combines multiple Observables to create an Observable whose values are calculated from the latest values of each of its input Observables.
    // src: https://rxjs.dev/api/index/function/combineLatest
    const onlineAndLoggedIn$ = combineLatest([
      this.networkService.isOnline$,
      this.authService.isLoggedIn$,
    ]);

    // If user is online and logged in, fetch menu for cache (used in offline mode)
    onlineAndLoggedIn$
      .pipe(
        untilDestroyed(this),
        map(([online, loggedIn]) => online && loggedIn),
      )
      .subscribe(result => {
        if (result) {
          //1 hour = 3,600,000 ms
          const ms = 3600000;
          this.getEquipmentMenuCache(ms);
          if (this.authService.hasToolsSectionAccess()) {
            this.getStaticVarsCache(ms);
          }
        }
      });
  }

  async addToHomeScreen() {
    if (localStorage.getItem('a2hs_message')) {
      localStorage.removeItem('a2hs_message');
    }

    const ready = await this.platform.ready();
    const isRoutePublicEquipment = (routeString: string) =>
      routeString
        .toLowerCase()
        .includes(this.ROUTE_URLS.EQUIPMENT_DETAILS_PUBLIC);

    if (ready && this.isMobileWeb) {
      // Subscribe to router events to get the URL after navigation
      // This is necessary, because URL is always '/' initially
      this.router.events
        .pipe(
          untilDestroyed(this),
          filter(event => event instanceof NavigationEnd),
        )
        .subscribe(async event => {
          const a2hsContainerExists =
            !!document.querySelector('.a2hs__container');

          if (
            event instanceof NavigationEnd &&
            !isRoutePublicEquipment(this.router.url) &&
            !a2hsContainerExists // Add the banner/prompt to Add to Home Screen only if it does not already exist
          ) {
            AddToHomeScreen(a2hsOptions);

            // Set status bar color (shows at the top of mobile screens; originally blue)
            await StatusBar.setStyle({ style: Style.Light });
          }
        });
    }
  }

  // closeMenu() used in ion-router-outlet; needed to close menu on "Settings" click; not sure why
  closeMenu() {
    this.menu.close();
  }

  isSelected(menuItem: MenuItem): boolean {
    let url = menuItem.url;

    // concat menuItem.url with key of menuItem.args and respective value
    if (menuItem.args) {
      const argsArray = [];
      // Loop through each key-value pair in menuItem.args
      for (const [key, value] of Object.entries(menuItem.args)) {
        // replace / with % encoded representation (%2F)
        const mappedValue = value.replace(/\//g, '%2F');
        argsArray.push(`${key}=${mappedValue}`);
      }

      url = `${menuItem.url};${argsArray.join(';')}`;
    }

    return this.router.url.endsWith(url);
  }

  navigate(url: string, args?: Params) {
    const requirePromptBeforeLeaving = [
      ROUTE_URLS.TOOLS_UPLIFT,
      ROUTE_URLS.SAFETY_SAFETY_INCIDENT_REPORT,
      ROUTE_URLS.TOOLS_PICK_ORDER,
    ];

    const currentUrl = this.router.url; // Get the current URL

    // Check if the current URL includes any of the routes in requirePromptBeforeLeaving
    const shouldPrompt = requirePromptBeforeLeaving.some(route =>
      currentUrl.includes(route),
    );

    if (shouldPrompt) {
      this.promptNavigate(url, args);
      return;
    }

    this.router.navigate([url, { ...args }]);
  }

  async promptNavigate(url: string, args?: Params) {
    const confirmationPrompt = await this.actionSheetCtrl.create({
      header: `Are you sure you want to leave this page? All data will be lost.`,
      buttons: [
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            // navigateByUrl with these options ensures destroy
            this.router.navigateByUrl(url, { replaceUrl: true, ...args });
          },
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });

    await confirmationPrompt.present();
  }

  private getEquipmentMenuCache(timeInMilliseconds: number): void {
    this.equipmentService
      .getMenu()
      .pipe(untilDestroyed(this))
      .subscribe(() => {});

    // Execute on interval
    interval(timeInMilliseconds).subscribe(() => {
      this.equipmentService
        .getMenu()
        .pipe(untilDestroyed(this))
        .subscribe(() => {});
    });
  }

  private getStaticVarsCache(timeInMilliseconds: number): void {
    this.systemService
      .getStaticVars()
      .pipe(untilDestroyed(this))
      .subscribe(() => {});

    // Execute on interval
    interval(timeInMilliseconds).subscribe(() => {
      this.equipmentService
        .getMenu()
        .pipe(untilDestroyed(this))
        .subscribe(() => {});
    });
  }

  private async getUserInfo() {
    this.user = await this.userService.user$;

    this.userService
      .profilePicture()
      .pipe(untilDestroyed(this))
      .subscribe(picture => {
        if (picture) {
          this.profilePhoto = picture;
          this.objectUrl = URL.createObjectURL(picture);
        }
      });
  }
}
