<form [formGroup]="hourMeterForm">
  <ion-list
    lines="full"
    class="md:max-w-2xl !mx-auto !my-0 md:py-0 md:mt-4 dark:md:border md:border-t md:border-l md:border-r">
    <ion-item color="light" lines="full" class="text-center">
      <ion-label>
        <p class="ion-text-center !text-lg !font-bold uppercase">Hour Meter</p>
      </ion-label>
    </ion-item>

    <!-- Product -->
    <ion-item
      class="w-full"
      [disabled]="allProducts.length === 0 || !productsAreFiltered">
      <ion-label
        text-wrap
        slot="start"
        class="uppercase font-semibold min-w-fit max-w-fit !mr-2">
        <ion-text>Product</ion-text>

        @if(hourMeterForm.controls.product.invalid){
        <ion-icon
          class="align-text-top mx-2 required"
          name="alert-circle"
          aria-label="Required"></ion-icon>
        } @else if(hourMeterForm.controls['product'].value){
        <ion-button id="product-details" fill="clear" class="align-middle mr-5">
          <ion-icon name="information-circle"></ion-icon>
        </ion-button>
        }
      </ion-label>

      @if(isLoadingProducts){
      <ion-spinner
        slot="end"
        name="dots"
        class="align-middle mx-2"></ion-spinner>
      } @else{
      <ion-input
        id="select-product"
        slot="end"
        text-wrap
        class="ml-0 mr-2 ion-text-right uppercase min-w-max"
        [ngClass]="hourMeterForm.controls['product'].value ? '' : 'mr-4'"
        [value]="hourMeterForm.controls['product'].value?.RSPF_PROD_N"
        (click)="openSelectProductModal()"
        placeholder="PRODUCT"></ion-input>
      }
    </ion-item>

    <!-- Meter Amount  -->
    <ion-item
      class="w-full"
      [disabled]="
        allProducts.length === 0 || hourMeterForm.controls.product.invalid
      ">
      <ion-label
        text-wrap
        slot="start"
        class="uppercase font-semibold min-w-fit max-w-fit !mr-2">
        <ion-text>Meter Amount</ion-text>
      </ion-label>
      @if (!hourMeterForm.controls.meter.valid){
      <ion-button id="meter-invalid" fill="clear" class="align-middle mr-5">
        <ion-icon
          class="align-middle mx-1 required"
          name="alert-circle"
          aria-label="Required"></ion-icon>
      </ion-button>
      }

      <ion-input
        type="number"
        inputmode="decimal"
        formControlName="meter"
        placeholder="0"
        min="0"
        class="ion-text-right"></ion-input>
    </ion-item>

    <!-- Current Meter -->
    @if(hourMeterForm.controls['product'].value){
    <ion-item class="w-full" class="opacity-40">
      <ion-label
        text-wrap
        slot="start"
        class="uppercase font-semibold min-w-min max-w-fit !mr-2">
        <ion-text>Current Meter</ion-text>
      </ion-label>

      <ion-text slot="end" class="ion-text-right mr-2">
        {{ hourMeterForm.controls['product'].value.LAST_METER }}
      </ion-text>
    </ion-item>
    }
  </ion-list>
</form>

<!-- Product Details Popover -->
@if(hourMeterForm.controls['product'].value){
<ion-popover
  alignment="center"
  trigger="product-details"
  triggerAction="click"
  arrow="false">
  <ng-template>
    <ion-content class="ion-padding">
      <ion-item lines="full">
        <ion-text class="font-semibold text-lg mx-auto">
          Product Details
        </ion-text>
      </ion-item>
      <ion-item lines="none">
        <ion-label>
          <ion-row class="p-0">
            <ion-col class="p-0">
              <p class="uppercase text-gray-500">Product Number</p>
              <h2 class="!font-semibold">
                {{ hourMeterForm.controls['product'].value.RSPF_PROD_N }}
              </h2>
            </ion-col>
          </ion-row>
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-label>
          <ion-row class="p-0">
            <ion-col class="p-0">
              <p class="uppercase text-gray-500">Description</p>
              <h2 class="!font-semibold">
                {{ hourMeterForm.controls['product'].value.RSPF_DESC }}
              </h2>
            </ion-col>
          </ion-row>
        </ion-label>
      </ion-item>
    </ion-content>
  </ng-template>
</ion-popover>
}

<!-- Meter Minimum Popover Message -->
@if ( !hourMeterForm.controls.meter.valid) {
<ion-popover
  alignment="center"
  trigger="meter-invalid"
  triggerAction="click"
  [arrow]="false">
  <ng-template>
    @if(hourMeterForm.controls['meter'].errors?.['required']){
    <ion-content class="ion-padding">Meter Amount is required.</ion-content>
    } @else if(hourMeterForm.controls['meter'].errors?.['min']){
    <ion-content class="ion-padding">
      Amount entered is lower than last recorded amount.
    </ion-content>
    } @else if(hourMeterForm.controls['meter'].errors?.['max']){
    <ion-content class="ion-padding">
      Maximum meter digits of 8 exceeded.
    </ion-content>
    } @else {
    <ion-content class="ion-padding">Meter Amount is invalid.</ion-content>
    }
  </ng-template>
</ion-popover>
}
